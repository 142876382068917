import { isEmpty } from "lodash";

const MessagePageCreator = (msgPageData, value, sticky) => {
  let container = [];
  let stickyComponent = [];
  const [textMsg] = msgPageData.filter(
    (element) => element.dataType === "obj_simple-text-message"
  );
  console.log(msgPageData, "loader");
  if (!isEmpty(msgPageData)) {
    msgPageData.map((collection) => {
      {
        console.log(collection, "collection");
      }
      if (collection.uid === "upcoming-fixture") {
        container.push({
          component: "UpcomingFixture",
          data: collection.data,
        });
      } else if (collection.uid === "top-players") {
        container.push({
          component: "TopPlayersTeamCard",
          data: collection.data,
        });
      } else if (collection.dataType === "obj_team-form-comparision") {
        container.push({
          component: "TeamComparisonCard",
          chatText: textMsg.data.message,
          userText: value,
          data: [collection.data],
        });
      } else if (collection.uid === "lineups") {
        container.push({
          component: "TeamLineUp",
          data: [collection.data],
        });
      } else if (collection.uid === "head-to-head") {
        container.push({
          component: "HeadToHead",
          data: [collection.data],
        });
      } else if (collection.uid === "news") {
        container.push({
          component: "NewsArticle",
          data: [collection.data],
        });
      } else if (collection.uid === "user-message") {
        container.push({
          component: "Message",
          data: collection.data,
        });
      } else if (collection.uid === "bot-message") {
        container.push({
          component: "Message",
          data: collection.data,
        });
      } else if (collection.dataType === "obj_stick-action-activity") {
        container.push({
          component: "PredictionCardUserSelection",
          data: collection.data,
        });
      } else if (collection.uid === "content-additional-information") {
        container.push({
          component: "Question",
          data: collection.data,
        });
      } else if (collection.uid === "team-preferences") {
        container.push({
          component: "BotQuestion",
          data: collection.data,
        });
      } else if (collection.uid === "suggestions") {
        container.push({
          component: "SuggestionCard",
          data: collection.data.suggestions,
        });
      } else if (collection.uid === "post-fixture") {
        container.push({
          component: "UpcomingMatchCard",
          data: collection.data,
        });
      } else if (collection.uid === "player-of-the-match") {
        container.push({
          component: "PlayerOfTheMatchCard",
          data: collection.data,
        });
      }
    });
  }

  if (sticky) {
    return stickyComponent;
  } else {
    return container;
  }
};

export default MessagePageCreator;
