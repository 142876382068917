import React, { useEffect, useState } from "react";
import { EventEmitter } from "../../engine/events";
// Stylesheet
import "./index.scss";
import MessageLoaderNew from "../MessageLoaderNew";
//Images
const TypewrittingComponent = (props) => {
  useEffect(() => {
    EventEmitter.subscribe("textChange", (event) => TextChange(event));
  }, []);
  const [text, setText] = useState("");
  const TextChange = (event) => {
    setText((prev) => prev + event);
    props.scrollBottomDiv();
    props.contentLoadedFromEmitter();
  };

  return (
    <p className="new-bot-msg">
      {!text && <MessageLoaderNew />}
      {text}
    </p>
  );
};
export default TypewrittingComponent;
