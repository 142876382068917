import React, { useEffect, useState } from "react";
// Stylesheet
import Autosuggest from "react-autosuggest";
import axios from "axios";
import theme from "./index.css";
//Images
const Autosuggestion = (props) => {
  const [suggestions, setSugesstion] = useState(props.componentSuggest);

  const [value, setValue] = useState("");
  const [suggestionsList, setSuggestionsList] = useState([]);

  const getSuggestions = (inputValue) => {
    const inputValueLowerCase = inputValue.toLowerCase();
    return suggestions.filter(
      (suggestion) => suggestion.toLowerCase().indexOf(inputValueLowerCase) > -1
    );
  };

  const onSuggestionsFetchRequested = ({ value }) => {
    setSuggestionsList(getSuggestions(value));
  };

  const onSuggestionsClearRequested = () => {
    setSuggestionsList([]);
  };

  const onSuggestionSelected = (event, { suggestion }) => {
    setValue(suggestion);

    props.sendChat(suggestion, "team");
    props.setAutoSuggestion(false);
  };

  const inputProps = {
    value,
    onChange: (_, { newValue }) => setValue(newValue),
    placeholder: "Not here just type in",
    className: "autosuggest__input",
  };

  const renderSuggestion = (suggestion) => (
    <div className="autosuggest__suggestion">{suggestion}</div>
  );
  return (
    <div className="suggestion-main-div">
      <Autosuggest
        theme={theme}
        suggestions={suggestionsList}
        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        onSuggestionsClearRequested={onSuggestionsClearRequested}
        onSuggestionSelected={onSuggestionSelected}
        getSuggestionValue={(suggestion) => suggestion}
        renderSuggestion={renderSuggestion}
        inputProps={inputProps}
        className="autosuggest"
        renderSuggestionsContainer={({ containerProps, children }) => (
          <div
            {...containerProps}
            className="autosuggest__suggestions-container"
          >
            <ul className="autosuggest__suggestions-list">{children}</ul>
          </div>
        )}
      />
    </div>
  );
};
export default Autosuggestion;
