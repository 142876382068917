import React from "react";
import Loadable from "react-loadable";

const getLoadableComponent = (component) => {
  return Loadable({
    render: (loaded, props) => {
      const Component = loaded.default;
      return (
        <>
          {/*{children}*/}
          <Component {...props} />
        </>
      );
    },
    loader: () => import(`../components/${component}`),
    loading(props) {
      if (props.error) {
        return <div>Oops Something went wrong</div>;
      }
      return null;
    },
    delay: 0,
  });
};

const loadComponent = (component) => getLoadableComponent(component);

export default ({ component, ...rest }) => {
  const LoadableComponent = loadComponent(component);
  return <LoadableComponent {...rest} />;
};
