import React from "react";
// Stylesheet
import "./index.scss";
//Images
import backButton from "./../../assets/img/icons/back-button.svg";
import refreshButton from "./../../assets/img/home.svg";
import { useNavigate } from "react-router-dom";

const BackNav = (props) => {
  // let navigate = useNavigate();

  // const goToMessagePage = () => {
  //   navigate(`/`);
  // };
  return (
    <div className="back-nav">
      <img
        onClick={() => {
          props.setHomepage(true);
        }}
        className="back-nav__back-button"
        src={backButton}
        alt="back"
      />
      <img
        onClick={() => {
          props.setHomepage(true);
        }}
        className="back-refresh-button"
        src={refreshButton}
        alt="home"
      />
    </div>
  );
};
export default BackNav;
