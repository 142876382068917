import React, { useEffect, useState } from "react";
// Stylesheet
import "./index.scss";
//Images
import backButton from "./../../assets/img/icons/back-button.svg";
//Components

//packages
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Slide } from "react-toastify";

const Homepage = (props) => {
  const [botData, setBotData] = useState([]);
  // let navigate = useNavigate();
  // const goToMessagePage = () => {
  //   navigate(`/messagepage`);
  // };

  useEffect(() => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: "https://vb-api-llm.vyld.io/v1/chat/bots/list",
    };

    axios(config)
      .then(function (response) {
        setBotData(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  const startBot = (id) => {
    var data = {
      id: id,
    };

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "https://vb-api-llm.vyld.io/v1/chat/start",
      data: data,
    };

    axios(config)
      .then(function (response) {
        props.botDataHandler(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  //To show only one toast at a time
  const customId = "custom-id-yes";
  const notify = () =>
    toast.info("Coming soon!", {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      toastId: customId,
    });

  return (
    <div className="homepage">
      <ToastContainer transition={Slide} />
      <img
        onClick={() => {
          window.flutter_inappwebview.callHandler("closeWebView");
        }}
        className="homepage__back-btn"
        src={backButton}
        alt="back"
      />
      <div className="homepage__header-div">
        <h2 className="homepage__header">A place where you can choose</h2>
        <h2 className="homepage__sub-header">
          <span> experts </span>to talk to
        </h2>
      </div>
      <div className="homepage__bot-grid">
        {botData.map((bot) => (
          <div
            className="homepage__bot"
            key={bot.id}
            onClick={() => {
              if (bot.category === "Football") {
                startBot(botData.id);
                props.setHomepage(false);
                // goToMessagePage();
              } else {
                notify();
              }
            }}
          >
            <img src={bot.botImg} alt="bot-1" className="homepage__bot-img" />
            <h2 className="homepage__bot-name">{bot.category}</h2>
            <p className="homepage__bot-description">{bot.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Homepage;
