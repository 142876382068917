import React, { useEffect, useState, useRef } from "react";
// Stylesheet
import "./index.scss";
import "./styles.css";
//Images
import botSvg from "./../../assets/img/botSvg.svg";
import modalClose from "./../../assets/img/modalClose.svg";
import SearchBar from "../../components/SearchBar";
import Lottie from "lottie-react";
import intro from "./../../assets/animation/football_intro_new.json";
import TopLottie from "./../../assets/animation/top lottie.json";
import Autosuggestion from "./../../components/AutoSuggestion";
import loopAfterIntro1 from "./../../assets/animation/loop football.json";
import MessagePageCreator from "../../engine/MessagePageCreator";
import MessageLoader from "../../components/MessageLoader";
import MessageLoaderNew from "../../components/MessageLoaderNew";
import TypewrittingComponent from "../../components/TypewrittingComponent";
import { EventEmitter } from "../../engine/events";
//components
import BackNav from "../../components/BackNav";
import LoadComponent from "../../engine/ComponentFactory";
import axios from "axios";
import Suggestions from "../../components/Suggestions";
import mqtt from "mqtt/dist/mqtt";


const MessagePage = (props) => {
  const [connected, setConnected] = useState(false);
  const [mqttConnect, setMqttConnect] = useState(false);
  const [typeWritterMsg, setTypeWritterMsg] = useState("");
  const [autoSuggestion, setAutoSuggestion] = useState(false);
  const [suggestionLatest, setLatestSuggestion] = useState({})

  // console.log(sessionId, "[data]");

  // const [botFloatingMsg, setBotFloatingMsg] = useState("");
  //////////////////////////////////
  const scrollBottomDiv = () => {
    var div = document.querySelector(".outer-message-div");
    div.scrollTop = div.scrollHeight;
  };

  useEffect(() => {
    if (connected && props.selectedBot?.data?.sessionId) {
      const client = mqtt.connect(`wss://vb-mqtt-broker.vyld.io`, {
        username: "vyld-mqtt-user",
        password: "vyld-mqtt-user",
      });

      client.on("connect", function () {
        console.log("[mqtt] connected to server...");
        // alert("connected...")
        client.subscribe(`/chatbot/${props.selectedBot.data.sessionId}`);
        setConnected(true);
        // setMqttConnect(client);
      });

      client.on("message", (topic, message) => {
        console.log(JSON.parse(message));
        if (JSON.parse(message).data.message) {
          EventEmitter.dispatch("textChange", JSON.parse(message).data.message);
          // setTypeWritterMsg((prev) => prev + JSON.parse(message).data.message);
        }

        // alert("connected...")
        // client.subscribe(`/videos/${id}`)
      });
    }
    if (!connected) setConnected(true);
  }, [connected, mqttConnect, props.selectedBot?.data?.sessionId]);

  // let isTextAnimationRevealed2 = false;
  // const textAnimationReveal2 = (msg) => {
  //   if (!isTextAnimationRevealed2) {
  //     const text = msg;
  //     const words = text.split(" ");
  //     const mes = document.querySelectorAll(".bot-message");
  //     const messageRecommendationText = mes[mes.length - 1];
  //     messageRecommendationText.innerHTML = "";
  //     let i = 0;
  //     const textReveal = setInterval(() => {
  //       if (i < words.length) {
  //         messageRecommendationText.innerHTML += words[i] + " ";
  //         i++;
  //         scrollBottomDiv();
  //       } else {
  //         clearInterval(textReveal);
  //       }
  //     }, 100);
  //     isTextAnimationRevealed2 = true;
  //   }
  // };

  const [messageLoader, setMessageLoader] = useState(false);
  const [predictionCard, setPredictionCard] = useState(false);
  const [selectedUserSuggestion, setSelectedUserSuggestion] = useState("");
  const suggestionHandler = (msg) => {
    setSelectedUserSuggestion(msg);
    addSuggestion()
  };

  const addSuggestion = () => {
    console.log(data, suggestionLatest, Object.getOwnPropertyNames(suggestionLatest).length)
    if (Object.getOwnPropertyNames(suggestionLatest).length !== 0 && suggestionLatest !== null) {
      setData([...data, ...MessagePageCreator([suggestionLatest])]);
      setLatestSuggestion({})
      setTimeout(() => {
        scrollBottomDiv();
      }, 100);
    }

  }

  const [suggestions, setSuggestion] = useState([]);
  useEffect(() => {
    if (props.selectedBot.data)
      setSuggestion(props.selectedBot.data.suggestions);
  }, [props.selectedBot.data]);

  const [data, setData] = useState([]);

  const sendChat = (userInput, from) => {
    const backNav = document.querySelector(".back-nav");
    backNav.style.background = "#0a0b10";
    setBot(false);
    setMessageLoader(true);
    setEmitterContentLoaded(false);
    setTimeout(() => {
      scrollBottomDiv();
    }, 0);

    const userMessage = [
      {
        uid: "user-message",
        data: {
          message: userInput,
          type: "user",
        },
      },
    ];

    setData([...data, ...MessagePageCreator(userMessage)]);
    let botData;
    if (from === "team") {
      botData = {
        sessionId: props.selectedBot.data.sessionId,
        teams: [userInput],
      };
    } else {
      botData = {
        sessionId: props.selectedBot.data.sessionId,
        message: userInput,
      };
    }

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "https://vb-api-llm.vyld.io/v1/chat/send",
      data: botData,
    };

    axios(config)
      .then(async function (response) {
        const [bot] = response.data.data.filter(
          (data) => data.uid === "bot-message"
        );
        // setTimeout(() => {
        //   textAnimationReveal2(bot.data.message);
        // }, 500);
        setMessageLoader(false);
        setTypeWritterMsg("");

        const updatedResults = [];
        // let time = bot.data.message.split(" ").length * 100;

        for (const data of response.data.data) {
          if (data.isPopulated === false) {
            try {
              const card = {
                sessionId: props.selectedBot.data.sessionId,
                data: { meta: data.meta },
              };

              const generateConfig = {
                method: "post",
                maxBodyLength: Infinity,
                url: "https://vb-chatbot-dev.vyld.io/v1/chat/generate",
                headers: {},
                data: card,
              };

              const generateResponse = await axios(generateConfig);
              const generatedData = generateResponse.data.data;
              // setData((prevData) => [
              //   ...prevData,
              //   ...MessagePageCreator([generatedData]),
              // ]);
              updatedResults.push(generatedData);
            } catch (error) {
              console.log(error);
            }
          } else {
            // setData((prevData) => [...prevData, ...MessagePageCreator([data])]);
            updatedResults.push(data);
          }
        }

        updatedResults.map((result, i) => {
          if (result.uid === "bot-message") {
            setData((prevData) => [
              ...prevData,
              ...MessagePageCreator([result]),
            ]);
            setTimeout(() => {
              scrollBottomDiv();
              //textAnimationReveal2(bot.data.message);
            }, 100);
          } else if (result.uid === "suggestions") {
            setLatestSuggestion(result)
          }else {
            setTimeout(() => {
              setData((prevData) => [
                ...prevData,
                ...MessagePageCreator([result]),
              ]);
              // time = 1000;
              setTimeout(() => {
                scrollBottomDiv();
              }, 100);
            }, 0 * 1200);
          }
        });
        // setData(
        //   (prevData) => [...prevData, ...MessagePageCreator(updatedResults)],
        //   setTimeout(scrollBottomDiv, 100)
        // );
      })
      .catch(function (error) {
        if (error.code === "ERR_NETWORK") {
          const errorMsg = [
            {
              uid: "bot-message",
              data: {
                message: "Some Error occured. Please Try again",
              },
            },
          ];
          setData([...data, ...MessagePageCreator(errorMsg)]);
          setTimeout(() => {
            scrollBottomDiv();
          }, 100);
          setMessageLoader(false);
          setTypeWritterMsg("");
        }
      });
    setSelectedUserSuggestion("");
  };

  //////////////////////////////////
  const [userPreferences, setUserPreference] = useState();

  const [iniateChatData, setIniateChatData] = useState();
  const [chatSession, setChatSession] = useState("");
  const [start, setStart] = useState(false);
  const [afterTextComplete, setAfterTextComplete] = useState(false);

  const [clickState, setClickState] = useState(false);

  const [componentsArray, SetComponentsArray] = useState([]);
  const [botAnimationName, setBotanimationName] = useState(intro);
  const [msgRecoBottomSheet, setMessageRecoBottomSheet] = useState(true);
  const [lottieAnimation, setLottieAnimation] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);

  const [selectedText, setSelectedText] = useState();
  const [fistMessage, setFirstMessage] = useState(false);

  let isTextAnimationRevealed = false;
  const textAnimationReveal = (msg) => {
    if (!isTextAnimationRevealed) {
      const text = msg;
      const words = text.split(" ");
      const messageRecommendationText = document.querySelector(
        ".message-recomendation__text"
      );
      messageRecommendationText.innerHTML = "";
      let i = 0;
      const textReveal = setInterval(() => {
        if (i < words.length) {
          messageRecommendationText.innerHTML += words[i] + " ";
          i++;
          // if (i < 10) {
          //   bottomDiv();
          // }
        } else {
          clearInterval(textReveal);
          setFirstMessage(true);
        }
      }, 100);
      isTextAnimationRevealed = true;
    }
  };

  const StartAnimation = () => {
    const messageRecommendationDiv = document.querySelector(
      ".message-recomendation"
    );
    // const messageRecommendationBot = document.querySelector(
    //   ".message-recomendation__bot"
    // );
    const questionRevealText = document.querySelector(
      ".message-recomendation__text"
    );
    const suggestionTextDiv = document.querySelector(
      ".message-recomendation__chat-sug-div"
    );

    // setLottieAnimation(true);

    // messageRecommendationBot.style.transform = `scale(1)`;
    setTimeout(() => {
      messageRecommendationDiv.style.transform = `translateY(0)`;

      // messageRecommendationBot.classList.add("animation-class");
    }, 1000);
    setTimeout(() => {
      setLottieAnimation(true);

      // messageRecommendationBot.classList.add("animation-class");
    }, 2000);

    setTimeout(() => {
      // messageRecommendationBot.style.top = `10%`;
      // questionRevealText.classList.add("message-recomendation__text-animation");
      // textAnimationReveal();
    }, 2600);
    setTimeout(() => {
      suggestionTextDiv.classList.add("bottom-reveal-animation");
    }, 5200);
  };

  const lastIndex = useRef(componentsArray.length - 1);

  const currentIndexHandler = () => {
    if (currentIndex <= lastIndex.current) {
      setCurrentIndex(currentIndex + 1);
    }
    return currentIndex;
  };

  const [moveBot, setMoveBot] = useState(false);
  const [botmovement, SetBotMovement] = useState("-40%");
  const [bot, setBot] = useState(true);
  const [emitterContentLoaded, setEmitterContentLoaded] = useState(false);

  const [componentSuggest, setComponentSuggestion] = useState([]);
  const getTeamList = () => {
    var config = {
      method: "get",
      maxBodyLength: Infinity,
      url: "https://vb-api-llm.vyld.io/v1/chat/teams",
    };

    axios(config)
      .then(function (response) {
        setComponentSuggestion(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const contentLoadedfromEmitter = () => {
    setEmitterContentLoaded(true)
  }

  useEffect(() => {
    getTeamList();
    //start animation when the background and bot comes on top**
    const messageRecommendationDiv = document.querySelector(
      ".message-recomendation"
    );
    const botIntroText = document.querySelector(".bot-into-text");
    const suggesstionDiv = document.querySelector(".suggestions");
    const searchInput = document.querySelector(".search-bar");

    messageRecommendationDiv.style.transform = `translateY(0)`;

    setTimeout(() => {
      setBotanimationName(loopAfterIntro1);
      setStart(true);
    }, 4600);
    setTimeout(() => {
      botIntroText.classList.add("bot-intro-text-anim");
    }, 4800);
    setTimeout(() => {
      botIntroText.classList.remove("bot-intro-text-anim");
    }, 6600);
    setTimeout(() => {
      setMoveBot(true);
    }, 7000);
    setTimeout(() => {
      textAnimationReveal(
        "You can ask me anything football! or I can help you with"
      );
    }, 7500);
    setTimeout(() => {
      suggesstionDiv.classList.add("suggestions-animation");
    }, 8500);
    setTimeout(() => {
      searchInput.classList.add("search-input-div-animation");
    }, 9500);
  }, []);
  const [firstSelection, setFirstSelection] = useState(true);

  return (
    <div className="message-page">
      {!bot && (
        <>
          <div className="overlay" />
          <Lottie
            style={{
              // width: "70%",
              // height: "100%",
              marginInline: "auto",
            }}
            className="bot-svg-img"
            animationData={TopLottie}
            loop={true}
          />
          {/* <img className="bot-svg-img" src={botSvg} /> */}
        </>
      )}
      <BackNav setHomepage={props.setHomepage} />
      {bot && (
        <div
          className="botclass"
          style={{
            bottom: `${botAnimationName === intro ? "-12%" : "8%"}`,
            transform: `translateY(${moveBot && botmovement})`,
          }}
        >
          <h2 className="bot-into-text">
            <span>Hey</span>
            <br />
            I'm Lionel Ronaldo
          </h2>
          <Lottie
            style={{
              width: "70%",
              height: "100%",
              marginInline: "auto",
            }}
            animationData={botAnimationName}
            loop={botAnimationName === intro ? false : true}
          />
          <p className="message-recomendation__text"></p>
          <Suggestions
            suggestions={suggestions}
            suggestionHandler={suggestionHandler}
          />
        </div>
      )}
      {autoSuggestion && (
        <div className="suggestion-modal">
          <div
            onClick={() => {
              setAutoSuggestion(false);
            }}
            className="modal-overlay"
          ></div>
          <div className="suddestion-main-div">
            <Autosuggestion
              componentSuggest={componentSuggest}
              sendChat={sendChat}
              setAutoSuggestion={setAutoSuggestion}
            />
          </div>
          <img
            className="modal-cancel-btn"
            onClick={() => {
              setAutoSuggestion(false);
            }}
            src={modalClose}
            alt="X"
          />
        </div>
      )}

      {/* <div className="outer-message-div"> */}
      <div className="outer-message-div">
        <div className="message-recomendation">
          {data.map((item) => (
            <LoadComponent
              component={item.component}
              data={item.data}
              sendChat={sendChat}
              predictionCard={predictionCard}
              setPredictionCard={setPredictionCard}
              suggestionHandler={suggestionHandler}
              componentSuggest={componentSuggest}
              setAutoSuggestion={setAutoSuggestion}
            />
          ))}
        </div>
        {messageLoader && (
          <TypewrittingComponent scrollBottomDiv={scrollBottomDiv} contentLoadedFromEmitter = {contentLoadedfromEmitter}  />
        )}
        {messageLoader && emitterContentLoaded && (
          <MessageLoaderNew />
        )}
      </div>
      <SearchBar
        suggestionHandler={suggestionHandler}
        selectedUserSuggestion={selectedUserSuggestion}
        sendChat={sendChat}
        addSuggestion = {addSuggestion}
      />
    </div>
  );
};
export default MessagePage;
