import React from "react";
// Stylesheet
import "./index.scss";
const Suggestions = (props) => {
  return (
    <div className="suggestions">
      {props.suggestions.map((suggestion) => (
        <div
          onClick={() => {
            props.suggestionHandler(suggestion.message);
          }}
          className="suggestion-sub-div"
        >
          {suggestion.message}
        </div>
      ))}
    </div>
  );
};
export default Suggestions;
