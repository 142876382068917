import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Homepage from "./pages/HomePage";
import MessagePage from "./pages/MessagePage";
import { useState } from "react";

function App() {
  const [selectedBot, setSelectedBot] = useState({});
  const [homepage, setHomepage] = useState(true);
  const botDataHandler = (data) => {
    setSelectedBot(data);
  };

  return (
    <>
      {homepage ? (
        <Homepage setHomepage={setHomepage} botDataHandler={botDataHandler} />
      ) : (
        <MessagePage setHomepage={setHomepage} selectedBot={selectedBot} />
      )}
    </>
    // <Router>
    //   <Routes>
    //     <Route
    //       path="/"
    //       element={<Homepage botDataHandler={botDataHandler} />}
    //     />
    //     <Route
    //       path="/messagepage"
    //       element={<MessagePage selectedBot={selectedBot} />}
    //     />
    //   </Routes>
    // </Router>
  );
}

export default App;
