import React from "react";
// Stylesheet
import "./index.scss";
//Images
import sendBtn from "./../../assets/img/SearchBar/sendBtn.svg";
import sendBtnPink from "./../../assets/img/SearchBar/sendBtnPink.svg";
const SearchBar = (props) => {
  return (
    <div className="search-bar">
      <input
        type="text"
        className="search-bar__input"
        placeholder="Ask anything"
        value={props.selectedUserSuggestion}
        onChange={(e) => props.suggestionHandler(e.target.value)}
      />
      <img
        style={{height : '3.2rem'}}
        src={props.selectedUserSuggestion ? sendBtnPink : sendBtn}
        alt="send"
        className="search-bar__send-btn"
        onClick={() => {
          props.sendChat(props.selectedUserSuggestion);
        }}
      />
    </div>
  );
};
export default SearchBar;
