import React from "react";
// Stylesheet
import "./index.scss";
//Images
const MessageLoaderNew = () => {
  return (
    <div className="loader">
      <span></span>
      <span></span>
      <span></span>
    </div>
  );
};
export default MessageLoaderNew;
